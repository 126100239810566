/* BLOCK ITEM SLIDER */
.block-item-slider{
    height:100%; width:100%;
    position:relative;
    background-size:cover; background-position:center center; background-repeat:no-repeat;
    overflow:hidden;
    .title{
        margin:0px 0px 36px 0px;
        position:relative;
    }
    .item-text{
        margin-top:40px;
        text-align:center; font-size:1.3rem; line-height:1.5;
        .item-text-title{
            font-size:1.5rem;
        }
    }
}

/* Media query min-width: 768px */
@include breakpoint(large) {
    /* BLOCK ITEM SLIDER */
    .block-item-slider{
        .item-text{
            margin-top:30px; padding:0 15px;
            font-size:1.4rem; line-height:1.5;
        }
    }
}

/* Media query min-width: 1200px */
@include breakpoint(xxlarge) {
    /* BLOCK ITEM SLIDER */
    .block-item-slider{
        .title{
            margin:0px 0px 80px 0px;
        }
        .item-text{
            margin-top:45px;
            font-size:1.5rem;
            .item-text-title{
                font-size:1.7rem;
                
            }
        }
    }
}