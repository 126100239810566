/* BLOCK Download */
.download-wrapper{
    width:100%; margin-top:0px;
    position:fixed; z-index:100; bottom:0;
    background-color:$white; box-shadow: 0 10px 70px hsla(0,0%,50%,.2);
    color:$white; line-height:60px; 
    overflow:hidden;
    transition:margin 400ms linear;
    .logo-wrapper{
        .logo{height:30px; padding-right:20px; margin-right:20px;}
    }
    .nav-wrapper{
        padding-top:0px; padding-bottom:15px;
        .list-item{
            color:#636363; list-style-type:none;
            &.active{
                .list-item-anchor{color:$primary-color;}
            }
        }
        .menu-item{display:inline-block;}
        .list-item-anchor{
            padding:0 10px;
            border-right:1px solid #636363;
            font-size:13px; color:#636363;
            display:block;
            transition:color 250ms ease-in-out;
            &:hover{color:$white;}
        }
    }
}
/* Media query min-width:480px */
@include breakpoint(medium) {
    .download-wrapper{
        line-height:75px;
        .logo-wrapper{height:75px; padding:37px 2.5px 12.5px 0px;}
        .nav-wrapper{
            padding-top:40px; padding-bottom:0px;
            float:right;
        }
    }
}