/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Import settings_frontend.scss. For overwriting foundation variables
@import 'settings';

// Import fonts frontend
@import 'fonts_frontend.scss';

// Import all Foundation components
@import '../../../../bower_components/foundation-sites/scss/foundation.scss';

// Include the right mixins
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
// @include foundation-button;
// @include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
// @include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;

// For grouping your css in mixins
@import 'mixins';

// Import block styles
// @import 'op_components/block_contact_map';
// @import 'op_components/block_detail';
// @import 'op_components/block_doughnut_chart';
@import 'op_components/block_cards';
@import 'op_components/block_footer';
@import 'op_components/block_footer_map';
@import 'op_components/block_header';
// @import 'op_components/block_info';
// @import 'op_components/block_items';
@import 'op_components/block_item_slider';
// @import 'op_components/block_logo_slider';
@import 'op_components/block_text';
@import 'op_components/block_video';

// Import custom style
@import 'custom';

// Import slick carousel
 @import '../../../../bower_components/slick-carousel/slick/slick.scss';
 @import '../../../../bower_components/slick-carousel/slick/slick-theme.scss';