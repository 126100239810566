/* BLOCK VIDEO */

/// Calculates the height as a percentage of the width for a given ratio.
/// @param {List} $ratio - Ratio to use to calculate the height, formatted as `x by y`.
/// @return {Number} A percentage value for the height relative to the width of a responsive container.
@function ratio-to-percentage($ratio) {
    $w: nth($ratio, 1);
    $h: nth($ratio, 3);
    @return divide($h, $w) * 100%;
}

/// Divide the given `$divident` by the given `$divisor`.
///
/// @param {Number} $divident - The divident.
/// @param {Number} $divisor - The divisor.
/// @param {Number} $precision - The precision decimals for the division.
///
/// @return {Number} The product of the division.
@function divide($dividend, $divisor, $precision: 12) {
    $sign: if($dividend > 0 and $divisor > 0 or $dividend < 0 and $divisor < 0, 1, -1);
    $dividend: abs($dividend);
    $divisor: abs($divisor);
    @if $dividend == 0 {
        @return 0;
    }
    @if $divisor == 0 {
        @error "Cannot divide by 0";
    }
    $remainder: $dividend;
    $result: 0;
    $factor: 10;
    @while ($remainder > 0 and $precision >= 0) {
        $quotient: 0;
        @while ($remainder >= $divisor) {
            $remainder: $remainder - $divisor;
            $quotient: $quotient + 1;
        }
        $result: $result * 10 + $quotient;
        $factor: $factor * .1;
        $remainder: $remainder * 10;
        $precision: $precision - 1;
        @if ($precision < 0 and $remainder >= $divisor * 5) {
            $result: $result + 1;
        }
    }
    $result: $result * $factor * $sign;
    $dividend-unit: unit($dividend);
    $divisor-unit: unit($divisor);
    $unit-map: (
        "px": 1px,
        "rem": 1rem,
        "em": 1em,
        "%": 1%
        );
    @if ($dividend-unit != $divisor-unit and map-has-key($unit-map, $dividend-unit)) {
        $result: $result * map-get($unit-map, $dividend-unit);
    }

    @return $result;
}

.block-video {
    .responsive-embed {
        position: relative;
        height: 0;
        margin-bottom: 0;
        padding-bottom: ratio-to-percentage(16 by 9);
        overflow: hidden;

        iframe,
        object,
        embed,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}