@charset "UTF-8";

@font-face {
  font-family: "one-pager";
  src:url("/assets/fonts/frontend/one-pager.eot");
  src:url("/assets/fonts/frontend/one-pager.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/frontend/one-pager.woff") format("woff"),
  url("/assetsfonts/frontend/one-pager.ttf") format("truetype"),
  url("/assets/fonts/frontend/one-pager.svg#one-pager") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "one-pager" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "one-pager" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\61";
}
.icon-facebook:before {
  content: "\62";
}
.icon-instagram:before {
  content: "\63";
}
.icon-linkedin:before {
  content: "\64";
}
.icon-angle-left:before {
  content: "\67";
}
.icon-angle-right:before {
  content: "\68";
}
.icon-arrow-circle-27:before {
  content: "\65";
}
.icon-arrow-circle-28:before {
  content: "\66";
}
.icon-arrow-66:before {
  content: "\69";
}
.icon-arrow-65:before {
  content: "\6a";
}
.icon-code:before {
  content: "\6b";
}
.icon-google-plus:before {
  content: "\6c";
}
.icon-phone:before {
  content: "\6d";
}
.icon-download-12:before {
  content: "\6e";
}
.icon-pinterest-p:before {
  content: "\6f";
}
.icon-bold:before {
  content: "\70";
}
.icon-angle-down:before {
  content: "\73";
}
.icon-angle-up:before {
  content: "\74";
}
