/* BLOCK CARDS */
.block-cards {
    .card{
        max-width: 32rem;
        margin: 0 auto 2rem auto;
        display: flex;
        flex-direction: column;
        background-color: $white;
        .c-button{
            margin-bottom: 0;
        }
    }

    .c-card-image-wrapper{
        margin: 0;
        overflow: hidden;
        .c-card-image{
            width: 100%;
            transform: scale3d(1,1,1);
            transition: transform .4s ease-in-out;
        }
    }

    .c-card-overlay{
        position: relative;
        z-index: 1;
        overflow: hidden;
        &::before{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 5;
            content: '';
            background-color: rgba($tertiary-color, .6);
        }
    }

    .c-card-overlay-title{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 15;
        color: $white;
        font-size: 4rem;
        font-weight: 600;
        transform: translate(-50%, -50%);
    }

    .card-section{
        padding: 2rem;
        flex: 1 0 auto;
        font-size: 1.4rem;
        border: 5px solid $tertiary-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}

@include breakpoint(medium){
    .block-cards {
        .c-card-overlay-title {
            font-size: 2.5rem;
        }
    }
}

@include breakpoint(large){
    .block-cards {
        .card{
            max-width: none;
            height: 100%;
            margin: 0;
        }

        .c-card-overlay-title{
            font-size: 4rem;
        }
    }
}

@include breakpoint(xlarge){
    .block-cards {
        .c-card-overlay-title{
            font-size: 6rem;
        }
    }
}