/* BLOCK TEXT / IMAGE */
.block-text{
    @include position($position: relative);
    height:100%; width:100%;
    background-position:center center; background-repeat:no-repeat; background-size:cover;
    .text{margin-bottom: 40px;}
}
/* Media query min-width: 768px */
@include breakpoint(large) {
    /* BLOCK ITEM SLIDER */
    .block-text{
        .text{margin-bottom: 0;}
    }
}
/* Media query min-width: 1024px */
@include breakpoint(xlarge) {
    .block-text{
        .insta-image{
            float: right;
        }
    }
}