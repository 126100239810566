/*
 * FOUNDATION OVERWRITES 
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */

$body-font-size: rem-calc(18);

body{font-size: $body-font-size;}
p{font-size: $body-font-size;}
ul, ol, dl{font-size: $body-font-size;}



/* SLICK SLIDER */
.slick-dots{
    left:0; bottom:-50px!important; 
    li {
        margin:0!important;
        &.slick-active{
            button{
                &:before{ 
                    color:$white!important; 
                    opacity:1!important;
                }
            }
        }
        button{
            &:hover{
                &:before{ 
                    color:$white!important; 
                    opacity:1!important;
                }
            }
            &:before{
                color:$primary-color!important;
                font-size:4.5rem!important;
                opacity:.6!important;
                transition:color .3s ease;
            }
        }
    }
}


/* Media query min-width:480px */
@include breakpoint(medium) {
    
}



/* Media query min-width:640px */
@include breakpoint(large) {

}



/* Media query min-width:1024px */
@include breakpoint(xlarge) {

}



/* Media query min-width:1200px */
@include breakpoint(xxlarge) {

}



/* Media query min-width:1600px */
@include breakpoint(xxxlarge) {

}