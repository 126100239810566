// For grouping your css in mixins
@import "mixins";
// To overwrite default Foundation style
@import "overwrites";
// General classes for returning styles
@import "general_styles";
// Keyframe animations that come in handy
@import "animations";

/*
 * CUSTOM STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance:styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */

.c-slide{
    padding: 0 1.5rem;
    position: absolute;
}
.slick-list{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slick-track {
    height: 100%;
}

.slider{
    .thumbnail{
        margin: 0 auto; 
    }
}

.header-slider{
    margin-top: 50px;
    position: relative; 
}


/* BUBBLE */
.bubble{
    position:absolute; z-index:100;
    animation-name:floating; animation-duration:3.5s; animation-iteration-count:infinite; animation-timing-function:ease-in-out;
    &:before{
        width:100%; height:100%;
        position:absolute;
        background-image:url('/assets/images/frontend/bubble.png'); background-repeat:no-repeat; background-size:contain;
        content:"";
    }
    &.x1{
        width:75px; height:75px;
        animation-duration:2s;
        animation-duration:4.5s;
        &:before{ transform:rotate(25deg); filter:blur(2px); }
    }
    &.x2{
        width:150px; height:150px;
        position:relative; top:100px; left:-150px; 
        animation-duration:4s;
        &:before{ transform:rotate(-25deg); filter:blur(1px); }
    }
    &.x3{
        width:200px; height:200px;
        position:relative; top:110px; left:-100px; 
        animation-duration:3.5s;
        &:before{ transform:rotate(-85deg); }
    }
    &.x4{
        width:200px; height:200px;
        position:absolute; top:50px; left:0; z-index:999999;
        &:before{ transform:rotate(-45deg); }
    }
    &.x5{
        width:300px; height:300px;
        position:absolute; top:240px; left:140px; z-index:999999;
        animation-duration:4s;
        &:before{ transform:rotate(20deg); }
    }
    &.x6{
        width:175px; height:175px;
        top:120px; left:-220px; 
        animation-duration:3.5s;
        &:before{ transform:rotate(-45deg); }
    }
    &.x7{
        width:150px; height:150px;
        top:250px; left:-75px; 
        animation-duration:4s;
        &:before{ transform:rotate(25deg); }
    }
    &.x8{
        width:75px; height:75px;
        top:375px; left:-200px;
        animation-duration:4.5s;
        &:before{ transform:rotate(25deg); }
    }
    &.x9{
        width:250px; height:250px;
        bottom:-400px; left:-100px; 
        &:before{ transform:rotate(25deg); }
    }
    &.x10{
        width:150px; height:150px;
        top:275px; left:120px; 
        animation-duration:4s;
        &:before{ transform:rotate(-25deg); filter:blur(1px); }
    }
    &.x11{
        width:75px; height:75px;
        top:405px; left:250px;
        animation-duration:4.5s;
        &:before{ transform:rotate(-25deg); filter:blur(2px); }
    }
}


/* FAQ */
.faq-item{
    border-bottom:1px solid $light-gray;
    position:relative;
    &:before{
        position:absolute; bottom:30px; right:0;
        font-family:'one-pager'; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none; speak:none; line-height:1; font-size:2.5rem; color:$medium-gray;
        content:'\73'; cursor:pointer;
        -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
    }
    &.active{ &:before{ content:'\74'; } }
    .faq-q{
        width:83.3333333333%; padding:30px 0; 
        cursor:pointer; 
        color:$white;
    }
    .faq-a{
        width:83.3333333333%; padding-bottom:50px;
        color:$medium-gray; display: none;
    }
}

/* HEADER */
.block-header{
    .title{ padding-left:15px; }
    .text{
        width:100%; margin-top:25px; margin-bottom:60%; padding-left:15px;
        position:relative;
        color:$white; font-size:2rem;
    }
    .app-banner{
        width:150%;
        position:absolute; top:42%; right:15px; z-index:5;
        .banner-wrapper{
            float:right;
            font-size:1.1rem;
            &:before{ transform:skew(-20deg); }
            .banner{
                width:220px;
                float:right; z-index:90;
                &:after{
                    width:100%; height:70%;
                    position:absolute; top:15%; left:40px; z-index:-1;
                    background:$secondary-color;
                    transform:skew(20deg);
                    content:"";
                }
                .thumbnail{
                    width:47%;
                    position:absolute; top:-33%; left:-40%;
                }
            }
        }
    }
    .wrapper{
        width:230px;
        position:relative; z-index:10;
        .thumbnail{ float:left; width:100px; z-index:100; position:relative; }
        .tekst{
            width:60%;
            position:absolute; top:50%; right:0; z-index:10;
            transform:translateY(-50%);
            font-size:1.2rem;
        }
        .banner{
            width:300px; margin-top:50px; margin-left:-15px;
            &:after{
                width:100%; height:70%;
                position:absolute; top:15%; left:40px; z-index:1;
                background:$secondary-color;
                transform:skew(20deg);
                content:"";
            }
        }
    }
    .app-wrapper{
        width:250px;
        position:absolute;  bottom:-100%; right:35px;
        .app{
            width:125px;
            display:inline-block; float:left;
        }
    }
    .slick-slide{
        .banner-wrapper{
            width:225px;
            position:absolute; top:30%; right:5%;
            .banner{ text-align:center; }
        }
    }
}


/* IMAGE LEFT */
.block-image-left{
    background: $white;
    .text{ 
        color: $primary-color; 
    }
    .title{ 
        text-align: left; 
        font-size: 3.5rem 
    }
    .image{ 
        margin-bottom: 30px;
    }
}


/* IMAGE RIGHT */
.block-image-right{
    color:$white;
    background:$primary-color;
    overflow:hidden;
    .title{  color:$white; text-align:left; font-size:3.5rem; }
    .image-wrapper{
        height:350px;
        position:relative;
        .image{
            max-width:none; height:150%;
            position:absolute; top:0; left:50%;
            transform:translateX(-50%);
        }
    }
    .banner-wrapper{
        width:250px;
        position:absolute; top:150px; z-index:9999;
        left: 31px;
        text-align:center;
        .banner{ font-size:2rem; }
    }
    .button{ 
        margin:0 15px 15px 0;
        display:inline-block; 
        white-space:nowrap; 
        &:after{ line-height:23px; } 
    }
    .c-slide{
        background-repeat: no-repeat; 
        background-position: left top; 
        background-size: cover;
        width: 953px;
        position: relative;
        left: 0px;
        top: 0px;
        height: 100%;
        z-index: 998;
    }
    .c-slider-wrapper{
        position: absolute;
        right: 0;
        width: 50%;
        top: 0;
        height: 100%;
        margin-bottom: 30px;
    }
    .slick-dots{
        position: relative;
        bottom: 0 !important;
        li{
            button{
                &:before{
                    color: $black !important; 
                }
            }
        }
        .slick-active{
           button{
                &:before{
                    color: $white !important; 
                }
            }
        }
    }
}



/* MAP BLOK */
.block-map{
    padding:100px 40px;
    .content{
        padding:55px 40px;
        position:relative; z-index:10;
        background-color:$white;
        color:$primary-color;
        .title{ font-weight:700; }
        .locations{
            padding-left:0;
            text-align:center; line-height:4rem;
        }
    }
    .map{
        width:100%; height:100%;
        position:absolute; top:0; left:0;
        background-image:url('/assets/images/frontend/map.jpg'); background-repeat:no-repeat; background-size:cover; background-position-x:80%;
        display:block;
    }
    .banner-wrapper{
        width:170px;
        position:absolute; top:-50px; left:50%; transform:translateX(-50%);
        .banner{
            font-size:1.5rem;
        }
    }
}


/* CONTACT BLOK */
.block-contact{
    height:100%; width:100%;
    position:relative;
    background:radial-gradient(circle at 50% 105%, $primary-color-light -20%, $primary-color 30%);
    .title{
        color:$white; text-align:left; font-size:4rem;
    }
    .text{
        color:$white;
        margin-bottom:40px;
    }
}


/* FOOTER */
.footer-wrapper{
    height: 225px;
    padding: 20px 0;
    font-size: 1.4rem;
    overflow: hidden;
    .footer-top {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid $white; 
    }
    .footer-logo{ 
        max-height: 60px;
    }
    .copyright {
        color: $white; 
        font-size: 1.2rem;
    }
    a {
        color: $white;
        &:hover {
            color: $secondary-color;
        }
    }
}

.download-wrapper{
    height:60px;
    .dwnld-wrapper{
        text-align:center;
        .dwnld-android{
            height:50px; padding:10px 15px; margin:0 10px;
            font-size:1.6rem; font-weight:700; color:$white;
            border-radius:5px;
            background:$secondary-color;
        }
        .dwnld-ios{
            height:50px; padding:10px 15px; margin:0 10px;
            font-size:1.6rem; font-weight:700; color:$white;
            border-radius:5px;
            background:$primary-color;
        }
    }
}

 /* LINKS */
 .c-link{
    color: $primary-color;
    text-decoration: none;
    line-height: inherit;
    cursor: pointer;
    transition: color .7s ease;
    &.white{
        color: $white;
        &:hover{
            color: darken(#ffffff, 50%) !important;
        }
    }
}

.c-link-underlined {
    color: $primary-color;
    border-color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
    transition: color .7s ease;
    &:hover, &:focus{
        border-color: transparent;
        color: $primary-color-darker;
    }
    &.white{
        color: $white;
        border-color: $white;
        &:hover{
            color: darken(#ffffff, 50%) !important;
            border-color: transparent;
        }
    }
}





/* Media query min-width:480px */
@include breakpoint(medium) {
    
}



/* Media query min-width:640px */
@include breakpoint(large) {
    /* HEADER */
    .block-header{
        .text{
            width:100%;
        }
        .app-banner{
            width:60%;
            position:absolute; bottom:10%; left:-25px; top:inherit;
             
            .banner-wrapper{
                font-size:1.4rem;
                &:before{ transform:skew(-20deg); }
                .banner{
                    width:210px;
                    float:right;
                    .thumbnail{
                        width:100px;
                        position:absolute; top:0; left:-100px;
                    }
                }
            }
            
        }
    }

    /* MAP BLOK */
    .block-map{
        padding:200px 0px;
        .content{
            .locations{
                padding-left:27px;
                text-align:left;
                column-count:2; column-gap:50px
            }
        }
    }

    /* CONTACT BLOK */
    .block-contact{
        .text{margin-bottom:0;}
    }

    /* FOOTER */
    .footer-wrapper {
        height: 181px;
    }
    .download-wrapper{
        height:75px;
    }
}



/* Media query min-width:1024px */
@include breakpoint(xlarge) {
    /* HEADER */
    .block-header{
        .text{
            width:60%;
        }
        .app-banner{
            width:50%;
            position:absolute; bottom:25%; left:-25px; top:inherit;
            .banner-wrapper{
                font-size:1.8rem;
                &:before{ transform:skew(-20deg); }
                .banner{
                    width:300px;
                    float:right;
                    .thumbnail{
                        width:184px;
                        position:absolute; top:-50%; left:-60%;
                    }
                }
            }
        }
        .wrapper{
            width:455px;
            .thumbnail{ width:185px; }
            .tekst{ font-size:1.6rem; }
            .banner{
                margin-left:-25px;
                &:after{
                    height:50%;
                    top:25%;
                }
            }
        }
    }

    /* IMAGE LEFT */
    .block-image-left{
        .image {
            margin-bottom: 0;
        }
    }
    /* MAP BLOK */
    .block-map{
        .banner-wrapper{
            width:250px;
            position:absolute; top:60px; left:-30px;
            .banner{ font-size:2rem;  }
        }
    }

    /* CONTACT BLOK */
    .block-map{
        .insta-image{
            float:right;
        }
    }

    .footer-wrapper {
        height: 162px;
    }
}



/* Media query min-width:1200px */
@include breakpoint(xxlarge) {

    /* MAP BLOK */
    .block-map{
        .banner-wrapper{
            left:-70px;
        }
    }
}
