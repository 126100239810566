/* BLOCK FOOTER MAP */
.block-footer-map{
    .contact-wrapper{
        margin-bottom:100px; padding:55px 40px;
        position:relative; z-index:10;
        background-color:$primary-color;
        color:$white;
        .title{
            margin-bottom:10px;
            text-align:left;
        }
        .company-info{margin:30px 0px;}
        .button{margin-top:35px;}
        .input{
            ::-webkit-input-placeholder{color:#636363;}
            ::-moz-placeholder{color:#636363;}
            ::-ms-input-placeholder{color:#636363;}
            width:100%;height:40px; margin:7px 0px; padding-left:10px;
            border:0; background-color:$secondary-color;
            font-size:1.5rem; color:$dark-gray;
            &.message{height:98px;}
        }
    }
}
.nav-wrapper{
    overflow:visible;
    .list-item{
        color:$white; list-style-type:none;
        &.active{
            .list-item-anchor{color:$primary-color;}
        }
    }
    .list-item-anchor{
        color:$white;
        display:block;
        transition:color 250ms ease-in-out;
    }
}

/* Media query min-width:768px */
@include breakpoint(large) {
    /* BLOCK FOOTER MAP */
    .block-footer-map{
        padding:100px 0px 0px 0px;
        .footer-gmaps{
            @include position(absolute, 2, 0, 0, 0, 0);
            width:100%; height:100%;
            background-image:url('/assets/images/frontend/temp_map.jpg'); background-repeat:no-repeat; background-size:cover;
            display:block;
        }
        .contact-wrapper{
            .input{
                width:90%; height:30px;
                &.input-right{width:100%;}
            }

        }
        .tab-bar{
            .nav-wrapper{
                padding-left:130px;
                overflow:visible; display:block;
                .list-item{
                    position:relative;
                    &:hover{
                        .subnav-wrapper{display:block;}
                    }
                }
                .list-item-anchor{
                    height:50px; padding:14px 8px 10px 8px;
                    position:relative; top:8px;
                    line-height:30px;
                    &::before{
                        position:absolute; top:auto; left:-3px;
                        color:$white;
                        content:"|";
                    }
                }
                .list-item:first-child{
                    .list-item-anchor::before{content:"";}
                }
                .subnav-wrapper{
                    position:absolute; z-index:5; top:50px;
                    margin-left:0;
                    background-color:$secondary-color;
                    display:none;
                    .list-item{
                        margin-left:0;
                        .list-item-anchor{height:auto; padding:6px 10px;}
                    }
                }
            }
            .list-item{
                &.active{
                    .list-item-anchor{color:$secondary-color;}    
                }
                .list-item-anchor{
                    &:hover{color:$secondary-color;}
                }
            }
            
        }
    }
}