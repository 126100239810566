/* BLOCK HEADER */
.block-header{
    padding:150px 0 60px 0;
    position:relative;
    background-position:center center; background-size:cover;
    &.small-padding{
        padding:60px 0!important;
    }
    .overlay{
        background-color:$primary-color;
    }
    .padding-title{padding:50px 0 100px 0;}
    .title-wrapper{
        margin-top:50px;
        position:relative; 
        .title{
            margin:0;
            font-size:3.2rem; color:$white; text-align:left; font-weight:700;
        }
    }
    &.small-header{
        // padding-top:0;
        background-color:#b6bbbb;
        .row{height:75px;}
        .logo{
            margin:15px 0 0 10px;
            float:left; position:relative; z-index:115;
            color:#ffffff;
        }
    }
    &.medium-header{
        .back{
            margin:20px 0 0 30px;
            float:left;
            color:$white; font-size:20px;
        }
        &.no-touch{
            &.bg-cover{
                background-position:0 -320px;
            }
        }
    }
    .main-menu-holder{
        width:100%; max-width:100%; height:100px;
        top:0; position:absolute; z-index:105;
        background:$primary-color;
        transition: top 0.5s ease;
        &.up{
            top:-100px;
        }
        &.down{
            top:0;
        }
        &.sticky{
            position:fixed; z-index:500;
            .menu-icon{
                .icon{
                    &:before{ background:$white;}
                    &:after{ background:$white; box-shadow:0 8px 0 0 $white;}
                }
                .collapsed{
                    &:after{box-shadow:0 0px 0 0 $white;}
                }
                .crossed{
                    &:after{box-shadow:none;}
                }
            }
        }
        .menu-icon{
            padding:0 0 50px 30px;
            position:absolute; right:15px; z-index:115;
            .icon{
                position:absolute; left:0px; top:28px;
                &:before{
                    width:30px; height:4px;
                    position:absolute; top:0px;
                    background:$white; border-radius:10px;
                    transition: transform 200ms linear, top 200ms linear, background-color 200ms linear;
                    content: "";
                }
                &:after{
                    width:30px; height:4px;
                    position:absolute; top:8px;
                    background:$white; border-radius:10px; box-shadow:0 8px 0 0 $white;
                    transition: transform 200ms linear, box-shadow 200ms linear, background-color 200ms linear;
                    content:"";
                }
            }
            .collapsed{
                &:before{background-color:$white; transition:transform 200ms linear, box-shadow 200ms linear, top 200ms linear; top:8px;}
                &:after{background-color:$white; box-shadow:0 0px 0 0 $white; transition: transform 200ms linear, box-shadow 200ms linear;}
            }
            .crossed{
                &:before{top:8px; background-color:$white; transform:rotate(45deg);}
                &:after{box-shadow:none; background-color:$white; transform:rotate(-45deg);}
            }
        }
        .logo{
            width:200px; margin:15px 0 0 10px;
            float:left; position:relative; z-index:115;
            color:$white;
        }
        .menu-wrapper{
            width:100%; height:0px; margin-top:0px; 
            position:fixed; top:0; z-index:110;
            background-color:rgba($primary-color, 0.9);
            font-size:20px; text-align:center;
            overflow:hidden;
            transition:height .4s ease-out;
            &.expanded{
                height:100%;
            }
        }
        .menu-item{
            width:100%; padding:0 0 3px 0;
            position:relative;
            line-height:32px;
            display:inline-block;
        }
        .menu{
            position:relative; top:50%;
            transform:translateY(-50%);
        }
        .menu-anchor{
            padding:0 7px;
            color:$white; font-size:1.6rem; line-height:75px;
            transition:color .7s ease;
            &:hover{
                color:$primary-color-light;
            }
        }
    }
}
.no-header{padding:20px 0 0 30px;}
.sticky-top-anchor{ @include position($position: absolute, $top: 100px, $right: 0); }

/* Media query min-width: 480px */
@include breakpoint(medium) {
    /* BLOCK HEADER */
    .block-header{
        .title-wrapper{
            .title{font-size:3.8rem;}
        }
    }
}

/* Media query min-width: 768px */
@include breakpoint(large) {
    /* BLOCK HEADER */
    .block-header{
        .main-menu-holder{
            .menu-wrapper{
                width:inherit;
                overflow:inherit;
                position:relative;
            }
            .menu-item{
                width:inherit;
            }
            .menu{
                margin-right:10px;
                float:right;
                display:inline-block;
                transform:translateY(-0%);
            }
            .logo{
                margin-top:10px;
                .logo-img{max-width:75%;}
            }
        }
        .menu-icon{display:none;}
        .title-wrapper{
            .title{font-size:4rem;}
        }
    }
}

/* Media query min-width: 1024px */
@include breakpoint(xlarge) {
    /* BLOCK HEADER */
    .block-header{
        // padding-top:60%;
        .main-menu-holder{
            .menu{margin-right:110px;}
            .logo{
                margin-left:110px; margin-top:10px;
                .logo-img{max-width:100%;}
            }
        }
        .title-wrapper{
            .title{font-size:5rem;}
        }
    }
}

/* Media query min-width: 1200px */
@include breakpoint(xxlarge) {
    /* BLOCK HEADER */
    .block-header{
        .title-wrapper{
            .title{font-size:6rem;}
        }
    }
}