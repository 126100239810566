/*
* CUSTOM MIXINS
*
* Some things in CSS are a bit tedious to write, especially with CSS3 and the many vendor prefixes that exist. 
* A mixin lets you make groups of CSS declarations that you want to reuse throughout your site. 
* You can even pass in values to make your mixin more flexible. 
*/

/* POSITIONING */
@mixin position(
    $position: static, 
    $zindex: 1, 
    $top: 0, 
    $left: 0, 
    $right: auto, 
    $bottom: auto
){
    position: $position;
    z-index: $zindex;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;  
}